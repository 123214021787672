export enum drivingMode {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
  DRIVING = 'driving'
}

class Driving {
  private mode: boolean = false
  private play: boolean = false
  private depotStartPosition: any = {
    lat: null,
    lng: null
  }
  private lastPosition: any = {
    lat: null,
    lng: null
  }
  // private speed: number = 2500
  private speed: number = 5

  isDriving(): boolean {
    return this.mode
  }
  changeMode(val: boolean): boolean {
    this.mode = val
    return this.mode
  }
  isPlay(): boolean {
    return this.play
  }
  setPlay(): boolean {
    this.play = true
    return this.play
  }
  setStop(): boolean {
    this.play = false
    return this.play
  }
  setDepotStartPosition(value: any): any {
    this.depotStartPosition = { lat: value.lat, lng: value.lng }
    return this.depotStartPosition
  }
  isLastPosition(): any {
    const lat = this.lastPosition.lat || null
    const lng = this.lastPosition.lng || null
    if (lat === null || lng === null) {
      this.lastPosition = this.depotStartPosition
    }
    return this.lastPosition
  }
  setLastPosition(value: any): any {
    this.lastPosition = { lat: value.lat, lng: value.lng }
    return this.lastPosition
  }
  getSpeed(): number {
    return this.speed
  }
  changeSpeed(value: number) {
    this.speed = value
  }
  fastSpeed() {
    this.speed = this.speed < 10 ? this.speed + 1 : this.speed
  }
  slowSpeed() {
    this.speed = this.speed > 1 ? this.speed - 1 : this.speed
  }

  sleep = () =>
    new Promise((resolve) => setTimeout(resolve, driving.getSpeed()))

  // 使用しない
  // const stepsCoordinates = ref<any[]>([])
  // const drivePlanCoordinates = ref<any[]>([])
  // const startDriving = () => {
  //   if (path.value.length === 0) return
  //   const drivingSpeed = driving.getSpeed() * (driving.getSpeed() < 5 ? 5 : 3)
  //   if (context.refs.gMap) {
  //     context.refs.gMap.$mapPromise.then((map: any) => {
  //       const drivePath = new window.google.maps.Polyline({
  //         // path: drivePlanCoordinates.value,
  //         // geodesic: true,
  //         // strokeColor: '#219140',
  //         // strokeOpacity: 1.0,
  //         // strokeWeight: 5
  //         strokeColor: '#F44336',
  //         visible: polyVisible.value
  //       })
  //       // drivePath.setPath([])
  //       drivePath.setPath(drivePlanCoordinates.value)
  //       drivePath.setMap(null)
  //       drivePath.setMap(map)
  //       nextLatLng.value = new window.google.maps.LatLng(
  //         drivePlanCoordinates.value[drivingSpeed]
  //       )
  //       currentPosition.value.lat = nextLatLng.value.lat()
  //       currentPosition.value.lng = nextLatLng.value.lng()
  //     })
  //   }
  //   driving.setLastPosition(currentPosition.value)
  //   drivePlanCoordinates.value.splice(0, drivingSpeed)
  // }
}
const driving = new Driving()

export { driving }
