export type DailyReports = {
  seq: number
  planned_time: Date | string
  pickup_time: Date | string
  dropoff_time: Date | string
  pickup_place: string
  dropoff_place: string
  user_name: string
  passengers: number
  price: number
  remarks: string
  pass_detail: string
}

export type VehicleDailyReports = {
  planned_time: Date | string
  driver_id: number
  vehicle_id: number
  order_id: number
  company_name: string
  license_plate: string
  driver_name: string
  vehicle_name: string
}

export const getDailyReports = (
  axios: any,
  driver_id: number,
  date: string,
  vehicle_id: number
) => {
  return axios
    .$get(
      `/orders/dailyrepotrs?driver_id=${driver_id}&date=${date}&vehicle_id=${vehicle_id}`
    )
    .then((ret: any) => {
      return ret.map((item: DailyReports) => {
        return item
      })
    })
    .catch((err: any) => {
      console.log('DailyReports get error', err)
    })
}

export const getVehicleDailyReports = (
  axios: any,
  driver_id: number,
  date: string,
  vehicle_id: number
) => {
  return axios
    .$get(
      `/orders/vehicledailyrepotrs?driver_id=${driver_id}&date=${date}&vehicle_id=${vehicle_id}`
    )
    .then((ret: any) => {
      return ret.map((item: VehicleDailyReports) => {
        return item
      })
    })
    .catch((err: any) => {
      console.log('VehicleDailyReports get error', err)
    })
}
