














































































































import {
  createComponent,
  computed,
  ref,
  onMounted,
  onBeforeUnmount
} from '@vue/composition-api'
import { Vehicle, getAllVehicles, statusToString } from '~/models/vehicle'

type Header = {
  text: string
  value: string
}

type Contact = {
  id: number
  name: string
  to: string
}

export default createComponent({
  setup(_props, context) {
    const selectedVehicleId = ref<number>(0)
    const confirmMessage = ref<string>('')
    const showDialogVehicle = ref<boolean>(false)
    const usedVehicle = ref<boolean>(true)
    const vehicleStatus = computed(() => {
      return context.root.$store.state.vehicleStatus
    })
    const done_cardelete = ref<Boolean>(false)
    const vehicles = ref<Vehicle[]>([])
    const isDataTableLoading = ref<boolean>(false)
    const vehicle_lists = computed(() => {
      const values: any = []
      vehicles.value.forEach((v) => {
        // if (v.driver_id === current_driver.value) {
        const last_name = ref<string>('')
        const first_name = ref<string>('')
        if (v.current_driver) {
          const driver_name = v.current_driver.split(' ')
          last_name.value = driver_name[0]
          first_name.value = driver_name[1]
        }

        const vv = {
          id: v.id,
          name: v.name,
          license: v.license_plate,
          model: v.model,
          status: v.current_status,
          status_name: statusToString(v.current_status),
          driver_lastname: last_name.value,
          driver_firstname: first_name.value
        }
        values.push(vv)
        // }
      })
      values.sort((a: any, b: any) => {
        return a.value - b.value
      })
      return values
    })
    const vehicle_id = ref<number>(0)
    const showCardelete = (id: number) => {
      vehicle_id.value = id
    }
    const cardelete = () => {
      const selectVehicle = [
        {
          op: 'replace',
          path: '/current_driver',
          value: null
        },
        {
          op: 'replace',
          path: '/status_inactive',
          value: true
        }
      ]
      context.root.$axios
        .$patch(`/vehicles/${vehicle_id.value}`, selectVehicle)
        .then(() => {
          done_cardelete.value = false
        })
        .catch((err: any) => {
          console.log(err)
          // context.root.$nuxt.$emit('showSessionErrMessage', true)
        })
    }
    const fetchVehicles = async () => {
      if (context.root.$auth.user) {
        isDataTableLoading.value = true
        const [resVehicles] = await Promise.all([
          getAllVehicles(context.root.$axios).catch((err: any) => {
            console.log(err)
            // context.root.$nuxt.$emit('showSessionErrMessage', true)
          })
        ])
        vehicles.value = resVehicles
      }
    }
    const fetchingInterval = ref(0)
    onMounted(() => {
      fetchingInterval.value = window.setInterval(async () => {
        await fetchVehicles()
      }, 5000)
      fetchVehicles()
    })
    onBeforeUnmount(() => {
      clearInterval(fetchingInterval.value)
    })
    return {
      vehicle_lists,
      isDataTableLoading,
      vehicles,
      selectedVehicleId,
      showDialogVehicle,
      vehicleStatus,
      confirmMessage,
      usedVehicle,
      cardelete,
      showCardelete,
      done_cardelete
    }
  },
  auth: false
})
