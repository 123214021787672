













































import { createComponent } from '@vue/composition-api'
import manifest from '@/static/manifest.json'

export default createComponent({
  setup(_props) {
    const manifestJson = JSON.stringify(manifest)
    const manifestScript = JSON.parse(manifestJson)
    const version = manifestScript.version
    const sysname = manifestScript.name
    return {
      version,
      sysname
    }
  }
})
