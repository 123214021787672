

























import { computed, createComponent, SetupContext } from '@vue/composition-api'
import moment from 'moment'

type Message = {
  id: string
  message: string
  datetime: string
}

export default createComponent({
  setup(_props, context: SetupContext) {
    // ポップアップさせるメッセージ
    const newMessage = computed<Message | null>(() => {
      // Bindしたメッセージから getterで絞って取得
      const messages = context.root.$store.getters['message/getRecentMessages']
      // 件数があれば、先頭を取り出し
      if (messages.length > 0) {
        const newTopMessage = messages[0]
        return {
          id: newTopMessage.id,
          message: String(newTopMessage.message),
          datetime: formatDate(newTopMessage.created_at)
        }
      } else {
        return null
      }
    })

    const markAsRead = () => {
      context.root.$store.commit('message/markAsReadMessage', newMessage.value)
      // Firebaseのメッセージ追加
      const messageTxt = '確認しました'
      context.root.$store.dispatch('message/addMessage', { messageTxt })
    }

    const formatDate = (dateTimeString: any) => {
      const createdAt = moment(dateTimeString.toDate())
      const datetimeFormat = moment().isSame(createdAt, 'day')
        ? 'HH:mm'
        : 'YYYY/MM/DD HH:mm'
      return createdAt.format(datetimeFormat)
    }

    return {
      markAsRead,
      newMessage
    }
  }
})
