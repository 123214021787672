import { vuexfireMutations, firestoreAction } from 'vuexfire'
import { firestore, Timestamp } from '@/plugins/firestore'

const MESSAGE_COLLECTION_NAME =
  process.env.ctiEnv === 'production' ? 'messages_navi' : 'messages_navi_dev'
const messageRef = firestore.collection(MESSAGE_COLLECTION_NAME)
const loadTimestap = Date.now()

export const state = () => ({
  messages: [],
  readMessages: []
})

export const getters = {
  // メインコンソールのnew_message.vue用
  getRecentMessages: (state) => {
    // 全体配信ではないものかつ、新しいTimestamp以降のみを返し、さらに逆順にする
    return state.messages
      .filter((msg) => {
        return (
          !msg.to.includes('user_all') &&
          !state.readMessages.find((readmsg) => readmsg.id === msg.id) &&
          msg.created_at.toDate() >= loadTimestap
        )
      })
      .sort((a, b) => a.created_at.seconds - b.created_at.seconds)
  },
  // 運行情報用
  getNotifications: (state) => {
    return state.messages.filter((msg) => {
      return (
        // 全体配信のもののみ返す
        msg.to.includes('user_all')
      )
    })
  }
}

export const actions = {
  bindMessages: firestoreAction(({ bindFirestoreRef, rootState }) => {
    const ref = messageRef
      .where('to', 'array-contains-any', [
        'user_all',
        'driver' + rootState.auth.user.id
      ])
      .orderBy('created_at', 'desc')
    return bindFirestoreRef('messages', ref)
  }),
  addMessage: async ({ rootState }, { messageTxt }) => {
    const from = 'driver' + rootState.auth.user.id || ''
    const to = ['operator']
    try {
      await messageRef.add({
        from,
        to,
        message: messageTxt,
        created_at: Timestamp.now()
      })
    } catch (err) {
      console.log(err)
    }
  }
}

export const mutations = {
  // Vuexfireのミューテーションを追加して bindmethodを呼出し
  ...vuexfireMutations,

  markAsReadMessage(state, message) {
    state.readMessages.push(message)
  }
}
