import { vuexfireMutations } from 'vuexfire'
import { firestore } from '@/plugins/firestore'

export const mutations = {
  ...vuexfireMutations,

  updatePhoneNumber(state, { phoneNumber }) {
    state.phoneNumber = phoneNumber
  },
  updateVerCode(state, { verCode }) {
    state.verCode = verCode
  },
  updateUserId(state, { userID }) {
    state.userID = userID
  },
  updateCurrentVehicleId(state, { vehicleID }) {
    state.vehicleID = vehicleID
  },
  updateCurrentVehicleName(state, { vehicleName }) {
    state.vehicleName = vehicleName
  },
  updateDriverMessages(state, { driverMes }) {
    state.driverMes = driverMes
  },
  updateOperatorMessages(state, { opMes }) {
    state.opMes = opMes
  },
  updateTimeBuffer(state, { timeBuffer }) {
    state.timeBuffer = timeBuffer
  },
  // 2021-05-26 利用者制限対応 - start
  updateStatusConfirme(state, { confirme }) {
    console.log(confirme)
    state.statusConfirme = confirme
  },
  // 2021-05-26 利用者制限対応 - end
  updateStatusVehicle(state, { vehicleStatus }) {
    state.vehicleStatus = vehicleStatus
  },
  updateMapType(state, { maptype }) {
    state.maptype = maptype
  },
  updateSetHeadingUp(state, { isHeadingUp }) {
    state.isHeadingUp = isHeadingUp
  },
  // 車両選択のローカルtimestamp
  updateDateSelected(state, { date_selected }) {
    state.date_selected = date_selected
  },
  updateMenuDrawer(state, { fromMapMenuDrawer }) {
    state.fromMapMenuDrawer = fromMapMenuDrawer
  }
}

export const state = () => ({
  phoneNumber: '',
  verCode: '',
  userID: '',
  vehicleID: '',
  vehicleName: '',
  vehicleStatus: 'INACTIVE',
  driverMes: '',
  opMes: '',
  maptype: 'roadmap',
  isHeadingUp: false,
  fromMapMenuDrawer: false,
  timeBuffer: '',
  statusConfirme: '' // 2021-05-26 利用者制限対応
})

export const actions = {
  setOperatorAvailability: ({ state }, { available }) => {
    if (state.auth && state.auth.user && state.auth.user.id) {
      const data = { id: state.auth.user.id, available }
      return firestore
        .collection('operators_navi_dev')
        .doc(`operator${state.auth.user.id}`)
        .set(data)
        .then(() => {
          return data.id
        })
    }
    return Promise.reject(new Error('Not authorized'))
  }
}
// console.log(state.auth.user.id)
// export const exportedUserId = {state.auth.user.id
