











































import { createComponent, ref } from '@vue/composition-api'
export default createComponent({
  setup(_props, context) {
    const snackbar = ref<boolean>(false)
    const isSendingMessage = ref<boolean>(false)
    const operatorDialog = ref<boolean>(false)

    const sendMessage = () => {
      isSendingMessage.value = true
      const messageTxt = '電話してください'
      // Firebaseのメッセージ追加
      context.root.$store
        .dispatch('message/addMessage', { messageTxt })
        .then(() => {
          isSendingMessage.value = false
          snackbar.value = true
          operatorDialog.value = true
        })
    }
    return {
      operatorDialog,
      zIndex: 5,
      sendMessage
    }
  }
})
