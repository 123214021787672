export class Driver {
  public id!: number
  public first_name!: string
  public last_kana!: string
  public driving_licence_number!: string
  public phone_number!: string
  public sex: string = ''
  public last_name!: string
  public username: string = ''
  public first_kana!: string
  public email: string = ''
  public birthday!: string
  public address: string = ''
  public location_name: string = ''
  public password!: string

  constructor(props: {
    id: number
    first_name: string
    last_kana: string
    driving_licence_number: string
    phone_number: string
    sex: string
    last_name: string
    username: string
    first_kana: string
    email: string
    birthday: string
    location_name: string
    address: string
    password: string
  }) {
    Object.assign(this, props)
  }
}

export const getDrivers = async ($axios: any) => {
  const drivers: Driver[] = []
  const response = await $axios.$get('/drivers/')
  response.forEach((value: any) => {
    drivers.push(new Driver(value))
  })
  return drivers
}

export const searchByName = (
  $axios: any,
  keyword: string
): Promise<Driver[]> => {
  return $axios.$get(
    `/drivers/?filter=first_name like ${keyword} or last_name like ${keyword} or first_kana like ${keyword} or first_kana like ${keyword}`
  )
}

export const getById = ($axios: any, id: number): Promise<Driver> => {
  return $axios.$get(`/drivers/${id}`)
}
