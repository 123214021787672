




























































import { computed, createComponent, SetupContext } from '@vue/composition-api'
import moment from 'moment'

type Notification = {
  id: number
  datetime: string
  from: string
  content: string
}

export default createComponent({
  setup(_props, context: SetupContext) {
    const formatDate = (dateTimeString: any) => {
      const createdAt = moment(dateTimeString.toDate())
      const datetimeFormat = moment().isSame(createdAt, 'day')
        ? 'HH:mm'
        : 'YYYY/MM/DD HH:mm'
      return createdAt.format(datetimeFormat)
    }

    // 全体配信のみを取得して表示させる。日付だけ成型する
    const notifications = computed<Notification>(() => {
      const rawNotifications =
        context.root.$store.getters['message/getNotifications']
      return rawNotifications.map((msg: any) => ({
        id: msg.created_at.seconds,
        datetime: formatDate(msg.created_at),
        from: msg.from === 'operator' ? 'オペレーター' : msg.from,
        content: msg.message
      }))
    })

    return {
      notifications
    }
  }
})
